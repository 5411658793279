import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

const Dot = ({ selected }) => (
  <div className={clsx(
    styles.dot,
    selected ? styles.dotSelected : ''
  )}/>
);

export default ({ step }) => (
  <div className={clsx(
      styles.container,
      step === 3 ? styles.containerHidden : ''
    )}>
    <Dot selected={step >= 0}/>
    <Dot selected={step >= 1}/>
    <Dot selected={step >= 2}/>
  </div>
)